import dayjs from "dayjs";
import type { CustomerAccessToken } from "@/graphql/generated/graphql-operations";

interface CustomerData {
  customer: any | null;
  customerAccessToken: CustomerAccessToken | null;
}

const DEFAULT_CUSTOMER: CustomerData = {
  customer: null,
  customerAccessToken: null,
};

export const customerAccessTokenValidator = (
  argAccessToken?: CustomerAccessToken
): string | null => {
  // Use provided token or get from localStorage
  const accessToken: CustomerAccessToken | undefined =
    argAccessToken ||
    JSON.parse(
      localStorage.getItem("customer") || JSON.stringify(DEFAULT_CUSTOMER)
    )?.customerAccessToken;

  // Check if token exists and is not expired
  if (!accessToken?.accessToken || !accessToken?.expiresAt) {
    return null;
  }

  const isExpired = dayjs(accessToken.expiresAt).isBefore(dayjs());
  return isExpired ? null : accessToken.accessToken;
};

export async function logout(): Promise<void> {
  localStorage.setItem("customer", JSON.stringify(DEFAULT_CUSTOMER));
  fetch("/account/logout")
    .then(() => {
      window.location.href = "/account/login?session_expired=true";
    })
    .catch((error) => {
      console.error("Logout failed:", error);
      window.location.href = "/account/login";
    });
}

export async function getTokenOrLogoutIfInvalid(
  argAccessToken?: CustomerAccessToken
): Promise<string | null> {
  const isLoggedIn = window?.theme?.customer;
  const token = customerAccessTokenValidator(argAccessToken);

  if (!token && isLoggedIn) {
    await logout();
  }

  return token;
}
